<!-- pdi上报 -->
<template>
<div :class="['pdiEscalation', operatePdi ? 'pdiEscalation-pd' : '']">
  <!-- 基础信息 -->
  <div class="common-block item">
    <div class="vehicle">{{ detail.customerName }}</div>
    <div class="label">
      <div class="common-label-t">
        {{ detail.carOrderMaterial?.seriesName }} |
        {{ detail.carOrderMaterial?.modelName }} |
        {{ detail.carOrderMaterial?.colourName }} | 
        {{ detail.carOrderMaterial?.interiorName }}
      </div>
    </div>
    <div class="label">
      <div class="common-label-t">VIN：{{ detail.vin }}</div>
    </div>
  </div>
  <!-- 检测中 -->
  <template v-if="taskDetail.taskStatus==='4004002'">
    <div class="common-block item">
      <div class="common-title">{{ $t('车辆PDI结果') }}</div>
      <div class="tips" style="margin-top: 10px;">{{ $t('车辆PDI检测请求已于') }} {{ taskDetail.executionTime }} {{ $t('发送') }}，</div>
      <div class="tips">{{ $t('正在等待售后系统反馈PDI结果') }}</div>
    </div>
  </template>
  <!-- 历史记录 -->
  <div class="result-title">{{ $t('车辆PDI结果') }}</div>
  <template v-for="(item, index) in pdiHistoryList">
    <div :key="item.id" class="update-time">{{ $t('更新时间') }}:{{ item.executionTime }}</div>
    <div :key="item.id+index" class="common-block pdiItem">
      <div class="result">
        <div class="label">{{$t('PDI检测结果')}}:</div>
        <div :class="['pdiStatus', `pdiStatus_${item.result}`]">{{ item.result === 1 ? $t('通过'): $t('检测未通过') }}</div>
      </div>
      <div v-if="item.result===2">
        <div class="pdiTime">
          <span class="label">{{ $t('故障描述') }}:</span>
          <span class="time">{{ item.pdiFaultRemark }}</span>
        </div>
        <div class="pdiTime">
          <span class="label">{{ $t('维修建议') }}:</span>
          <span class="time">{{ item.pdiMntAdvise }}</span>
        </div>
        <div class="pdiTime">
          <span class="label">{{ $t('预计修复完成时间') }}:</span>
          <span class="time">{{ item.expectedRepairTime || '--' }}</span>
        </div>
      </div>
      <!-- 一个检查单据可能关联多个委托书 -->
      <div v-for="e in item.repairOrderList" :key="e.authNo" class="repair-item">
        <div v-for="field in fieldList" :key="field.key" class="pdiTime">
          <span class="label">{{field.label}}:</span>
          <span class="time">{{ e[field.key] || field.value }}</span>
        </div>
      </div>
      <div class="image">
        <span  v-for="(fileUrl,idx) in item.fileList" :key="idx">
          <van-uploader :fileList="[{url:fileUrl}]" :deletable="false" />
        </span>
      </div>
    </div>
  </template>
  <!-- 处理填写方案 弹窗 -->
  <van-dialog
    v-model="againPdi"
    show-cancel-button
    :cancel-button-text="$t('返回')"
    :confirm-button-text="$t('提交')"
    :before-close="handlerConfirm">
    <van-form ref="form" @submit.native.prevent>
      <van-field
        v-model="params.executionJson.dealStrategy"
        :label="$t('请选择处理方案')"
        required
        :rules="[{ required: true, message: $t('请选择处理方案') }]"
        class="typeField">
        <template #input>
          <van-radio-group v-model="params.executionJson.dealStrategy">
            <van-radio name="1">{{ $t('同意维修（维修后会重新同步PDI结果）') }}</van-radio>
            <van-radio name="2">{{ $t('换车（请发起换车流程）') }}</van-radio>
            <van-radio name="3">{{ $t('签署车辆状况告知书交付') }}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="params.executionJson.dealRemark"
        :label="$t('备注')"
        rows="2"
        autosize
        type="textarea"
        maxlength="200"
        placeholder="请输入留言"
        show-word-limit>
      </van-field>
    </van-form>
  </van-dialog>
  <div v-if="taskDetail.taskStatus==='4004001'" class="common-footer">
    <van-button class="btn submit" type="submit" @click="handleAgain">{{$t('处理方案填写')}}</van-button>
  </div>
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
export default {
  name: 'pdiEscalation',
  data() {
    return {
      taskDetail: {},
      params: {
        id: '',
        taskStatus: '4004002',
        executionJson: {
          taskCode: 'PDI_CONFIRM',
          dealStrategy: '',
          dealRemark: ''
        },
      },
      detail: {},
      operation: 'edit',
      fileList: [],
      submit: false,
      orderId: '',
      pdiHistoryList: [],
      taskConfigId: '',
      taskId: '',
      operatePdi: false,
      fieldList: [
        {
          label: this.$t('委托书信息'),
          key: 'authNo',
          value: ''
        },
        {
          label: this.$t('进店日期'),
          key: 'inStoreDate',
          value: ''
        },
        {
          label: this.$t('服务顾问'),
          key: 'serviceMan',
          value: ''
        },
        {
          label: this.$t('故障描述'), 
          key: 'faultRemark',
          value: ''
        }
      ],
      againPdi: false
    }
  },
  computed: {
    isComplete(){
      if (!this.pdiHistoryList.length) return false
      return this.pdiHistoryList[0]?.taskStatus === '4004003'
    }
  },
  mounted() {
    const { operation, id, taskConfigId, taskId, operatePdi } = this.$route.query
    this.operation = operation || 'edit'
    this.orderId = id
    this.taskConfigId = taskConfigId
    this.params.id = taskId
    this.operatePdi = operatePdi
    this.getDetail()
    this.getTaskDetail()
    this.getTaskHistory()
  },
  methods: {
    // 获取交付详情
    async getDetail() {
      const res = await deliveryServices.deliveryDetail({ id: this.orderId })
      this.detail = res || {}
    },
    async getTaskDetail(){
      deliveryServices.deliveryTaskDetail({
        taskId: this.params.id
      }).then(res=>{
        this.$set(this,'taskDetail',res)
      })
    },
    // 获取交付历史任务
    async getTaskHistory() {
      const params = {
        orderId: this.orderId,
        taskConfigId: this.taskConfigId || '11'
      }
      const res = await deliveryServices.deliveryTaskHistory(params)
      this.pdiHistoryList = res.filter(({ executionUserName }) => (executionUserName === '系统')).map(item=>{
        return {
          id: item.id,
          executionTime: item.executionTime,
          ...item.executionJson,
          taskStatus:item.taskStatus,
          fileList: item.executionJson?.attachUrl ? item.executionJson.attachUrl.split(',') : []
        }
      })
    },
    async handleAgain () {
      this.againPdi = true
      this.$refs.form.resetValidation()
    },
    async handlerConfirm(action, done) {
      if (action === 'confirm') {
        await this.$refs.form.validate().then(
          () => {
            this.onSubmit(done)
          },
          () => {
            done(false)
          }
        )
      } else {
        done()
      }
    },
    async onSubmit(done) {
      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      done()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    },
    editPdi() {
      this.operation = 'edit'
    },
  }
}
</script>
<style lang="less" scoped>
.typeField{
  display: flex;
  flex-direction: column;
  /deep/ .van-cell__title{
    padding-left: 8px;
    position: relative;
    color: @black;
    margin-bottom: 10px;
    &::before{
      content: ' ';
      left: 0;
      position: absolute;
      top: calc(50% - 6px);
      width: 2px;
      height: 12px;
      background: @yellow-text;
      border-radius: 0px 0px 0px 0px;
    }
  }
  .van-field__value{
    .van-radio-group{
      .van-radio{
        &:not(:last-of-type){
          margin-bottom: 8px;
        }
      }
    }
  }
}
.pdiEscalation{
  padding: 16px;
  &.pdiEscalation-pd{
    padding-bottom: 96px;
  }
  .item{
    padding: 12px;
    color: @black;

    .tips{
      font-size: 14px;
      color: gray;
      line-height: 24px;
      height: 24px;
    }
    .noPdi{
      color: #ee0a24;
      font-size: 12px;
      width: 100%;
      text-align: right;
      padding-bottom: 10px;
    }
    .common-t-l{
      &.required::after{
        content: '*';
        color: #EED484;
      }
    }
    &:not(:last-child){
      margin-bottom: 16px;
    }
    &.noPadding{
      padding-bottom: 0px;
    }
    .vehicle{
      font-size: 16px;
      // margin: 12px 0;
      margin-bottom: 12px;
    }
    .label{
      display: flex;
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
    /deep/ .van-field{
      // padding: 10PX 0;
    }
  }
  .pdiItem{
    padding: 12px;
    .result{
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .label{
        margin-right: 10px;
      }
    }
    .repair-item{
      margin-top: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .pdiStatus{
      // color: @black;
      color: #E3022C;
      
      &.pdiStatus_1{
        color: #029640;
      }
    }
    .pdiTime{
      color: gray;
      font-size: 14px;
      // padding-bottom: 12px;
      // border-bottom: 1px solid rgba(13,23,26,0.05);
      .time{
        position: relative;
        margin-left: 6px;
        padding-left: 6px;
        // &::before{
        //   content: "";
        //   position: absolute;
        //   top: 50%;
        //   left: 0;
        //   width: 1px;
        //   height: 70%;
        //   background-color: #D9D9D9;
        //   transform: translateY(-50%);
        // }
      }
    }
    .image{
      margin: 12px 0;
      height: 80px;
      .img{
        width: 80px;
        height: 80px;
      }
      /deep/ .van-uploader__upload{
        display: none;
      }
    }
    .des{
      padding-top: 12px;
      border-top: 1px solid rgba(13,23,26,0.05);
      word-wrap: break-word;
    }
  }
  .update-time{
    padding-left: 10px;
    margin-bottom: 4px;
    color: gray;
  }
  .result-title{
    padding-left: 10px;
    font-size: 16px;
    margin-bottom: 4px;
  }
}
</style>
